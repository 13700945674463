import { useSearchParams } from "next/navigation";
import Script from "next/script";
import { FC } from "react";

const AFFISE_INIT_QUERIES = ["clickid", "click_id"];

export const AffiseInitScript: FC = () => {
    const searchParams = useSearchParams();

    for (const key of searchParams.keys()) {
        if (AFFISE_INIT_QUERIES.includes(key)) {
            return (
                <Script
                    src="/assets/js/affise-init.js"
                    id="affise-init"
                    data-clickid={searchParams.get(key)}
                    async
                />
            );
        }
    }

    return null;
};
